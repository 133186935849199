//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PartnersList',
  components: {
    BaseImg: () => import('@motionelements/core/src/components/ui/BaseImg.vue'),
  },
  data() {
    return {
      partners: [
        {
          logo: 'logo-techinasia',
          title: 'Tech In Asia',
        },
        {
          logo: 'logo-thebridge',
          title: 'The Bridge',
        },
        {
          logo: 'logo-venturesquare',
          title: 'Venture Square',
        },
      ],
      partnersJA: [
        {
          logo: 'logo-nomura',
          title: 'Nomura',
        },
        {
          logo: 'logo-borndigital',
          title: 'ボーンデジタル',
        },
        {
          logo: 'logo-dhu',
          title: 'Digital Hollywood University',
        },
        {
          logo: 'logo-dronetimes',
          title: 'Dronetimes',
        },
        {
          logo: 'logo-aes',
          title: 'After Effects Style',
        },
        {
          logo: 'logo-videosalon',
          title: 'Video Salon',
        },
        {
          logo: 'logo-pronews',
          title: 'ProNews',
        },
      ],
      partnersZH: [
        {
          logo: 'logo-bnext',
          title: 'Bnext 數位時代',
        },
        {
          logo: 'logo-createlife',
          title: 'createlife',
        },
        {
          logo: 'logo-pcg-bros',
          title: '寶悍運動',
        },
        {
          logo: 'logo-incg',
          title: '映CG',
        },
        {
          logo: 'logo-dvasia',
          title: 'DV Asia',
        },
        {
          logo: 'logo-mydesy',
          title: '麥點子',
        },
        {
          logo: 'logo-thenewslens',
          title: '關鍵評論網',
        },
      ],
    };
  },
  computed: {
    partnersList() {
      switch (this.$i18n.locale) {
        case 'zh-hant':
          return [
            ...this.partners,
            ...this.partnersZH,
          ];
        case 'ja':
          return [
            ...this.partners,
            ...this.partnersJA,
          ];
        default:
          return this.partners;
      }
    },
  },
};
